import React from "react"
import styled from "styled-components"

import { CenterTitle } from "../common/text"
import { BannerContainer, BannerWrapper } from "../common/containers"
import { ornament } from "../../../assets/styles/helpers/common"

import Nav from "../nav/Nav"
import PostMeta from "../atoms/PostMeta"
import Categories from "../atoms/Categories"

import { minD, maxD } from "../../../assets/styles/helpers/devices"

import Calendar from "./../../../assets/images/svg/calendar.svg"

import SiteLanguage from "../../../helpers/siteLanguage"
import optimizedPath from "../../../helpers/optimizedPath"
import getImageBySrcSetAndSize from "../../../helpers/getImageBySrcSetAndSize"
import getRangedSrcSet from "../../../helpers/getRangedSrcSet"

const PageBannerWrapper = styled(BannerWrapper)`
  --padding-vertical: 3rem;
  --padding-horizontal: 3rem;

  &::after {
    top: -20px;
  }

  @media ${minD.laptopL} {
    &::before {
      height: 100%;
    }
  }

  @media ${maxD.mobileL} {
    padding-bottom: calc(var(--padding-vertical) * 1.4);

    --padding-vertical: 2.2rem;
    --padding-horizontal: 2.2rem;
  }
`

const ImageContainer = styled.figure`
  position: relative;

  &::before {
    ${ornament}

    width: 100%;
    height: 100%;
    inset: 0;
    z-index: 1;

    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.45) 0%,
      rgba(0, 0, 0, 0) 25%,
      rgba(0, 0, 0, 0.7) 100%
    );
  }
`

const HeaderImage = styled.img`
  width: 100%;
  height: 400px;
  object-fit: cover;

  @media ${maxD.mobileL} {
    height: 250px;
  }
`

const CategoriesContainer = styled.header`
  position: absolute;
  inset: var(--padding-vertical) var(--padding-horizontal);
  z-index: calc(var(--max-z-index) + 1);
`

const Meta = styled(PostMeta)`
  display: flex;
  align-items: flex-end;
`

const SingleMetaData = styled.span`
  display: flex;
  align-items: center;

  color: #fff;
`

const Icon = styled.svg`
  width: 1rem;
  height: 1rem;
  fill: #fff;

  margin-right: 0.75rem;
`

const DefaultHeader = ({ pageContext, title, image, date, categories }) => {
  if (image) {
    image.url =  getImageBySrcSetAndSize(image.srcSet, 'medium') ? getImageBySrcSetAndSize(image.srcSet, 'medium') : optimizedPath(image.sourceUrl)
    image.srcSet = getRangedSrcSet(image.srcSet, 'medium')
    image.alt = (SiteLanguage() !== "en" && image.atttachement_pl?.altTextPl) ? image.atttachement_pl?.altTextPl : image.altText
  }

  return (
    <BannerContainer
      style={{
        "--laptop-padding": "180px 0 0",
        "--mobile-padding": "170px 0 0",
      }}
    >
      <Nav pageContext={pageContext} />
      <PageBannerWrapper relative alignItems="fe">
        <CenterTitle
          as="h1"
          dangerouslySetInnerHTML={{ __html: title }}
          style={{ "--padding-top": "0", "--padding-bottom": "5rem" }}
        ></CenterTitle>
        <ImageContainer>
          {categories?.nodes && (
            <CategoriesContainer>
              <Categories categories={categories.nodes} />
            </CategoriesContainer>
          )}
          {image?.url && <HeaderImage image={image.url} srcSet={image.srcSet} alt={image.alt} />}
          <Meta>
            {date && (
              <SingleMetaData>
                <Icon as={Calendar} />
                {date}
              </SingleMetaData>
            )}
          </Meta>
        </ImageContainer>
      </PageBannerWrapper>
    </BannerContainer>
  )
}

export default DefaultHeader
